import { css } from "@emotion/react";

import {
  colors,
  breakpoints,
  timings,
} from "@mytutor/shared-react-web-components/lib/theme";

const popularSubjectsStyle = css`
  margin: 64px 0 32px;
  @media (max-width: ${breakpoints.tablet}px) {
    margin: 40px 0 24px;
  }

  .title {
    font-family: "BureauGrotesk", sans-serif;
    font-size: 64px;
    font-weight: 400;
    line-height: 64px;
    margin: 24px;
    text-align: center;

    @media (max-width: ${breakpoints.phone}px) {
      font-size: 36px;
      line-height: 36px;
      margin: 16px;
    }
  }

  .subjects {
    max-width: 1000px;
    text-align: center;
    padding: 0 5px;
    margin: 0 auto;

    p {
      font-size: 21px;
      font-weight: 500;
      letter-spacing: 0;
      padding: 10px;
      color: ${colors.neutral8};
      margin: 0;

      @media (max-width: ${breakpoints.largeDesktop}px) {
        p {
          font-size: 18px;
        }
      }
    }

    a {
      background-color: ${colors.neutral1};
      border-radius: 4px;
      border: 2px solid ${colors.neutral1};
      margin: 8px;
      display: inline-block;
      text-decoration: none;
      cursor: pointer;
      transition: ${timings.medium};

      &:hover {
        color: ${colors.neutral8};
        background-color: ${colors.primary5};
        border: 2px solid ${colors.primary5};
      }
      &:active {
        color: ${colors.neutral1};
        background-color: ${colors.primary8};
        border: 2px solid ${colors.primary8};
      }
      &:focus {
        color: ${colors.neutral8};
        background-color: ${colors.bgcolor2};
        border: 2px solid ${colors.primary5};
      }
    }

    .special {
      background-color: ${colors.bgcolor2};
      border: 2px solid ${colors.bgcolor2};
    }

    @media (max-width: ${breakpoints.phone}px) {
      .group3 {
        display: none;
      }
    }

    @media (max-width: ${breakpoints.smallPhone}px) {
      .group2 {
        display: none;
      }
    }
  }
`;

const subjectsURL = (subject: string) => {
  return `${process.env.NEXT_PUBLIC_ZEUS_URL}/get-started?subject=${subject}&step=Level`;
};

const PopularSubjects = () => (
  <section css={popularSubjectsStyle}>
    <h2 className="title">Pick a subject to get started</h2>
    <div className="subjects">
      <a className="group1" href={subjectsURL("Maths")}>
        <p>Maths</p>
      </a>
      <a className="group1" href={subjectsURL("English")}>
        <p>English</p>
      </a>
      <a className="group1" href={subjectsURL("Chemistry")}>
        <p>Chemistry</p>
      </a>
      <a className="group1" href={subjectsURL("Physics")}>
        <p>Physics</p>
      </a>
      <a className="group1" href={subjectsURL("Biology")}>
        <p>Biology</p>
      </a>
      <a className="group3" href={subjectsURL("Science")}>
        <p>Science</p>
      </a>
      <a className="group2" href={subjectsURL("Spanish")}>
        <p>Spanish</p>
      </a>
      <a className="group3" href={subjectsURL("French")}>
        <p>French</p>
      </a>
      <a className="group3" href={subjectsURL("German")}>
        <p>German</p>
      </a>
      <a className="group3" href={subjectsURL("History")}>
        <p>History</p>
      </a>
      <a className="group3" href={subjectsURL("Music")}>
        <p>Music</p>
      </a>
      <a className="group3" href={subjectsURL("Psychology")}>
        <p>Psychology</p>
      </a>
      <a className="group3" href={subjectsURL("Politics")}>
        <p>Politics</p>
      </a>
      <a
        className="group1 special"
        href={`${process.env.NEXT_PUBLIC_ZEUS_URL}/get-started?step=Subject`}
      >
        <p>All subjects</p>
      </a>
    </div>
  </section>
);

export default PopularSubjects;
